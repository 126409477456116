import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import "./scholarship-body.scss";
import { DateTime } from 'luxon';
import { useRouter } from 'next/router'
import { DESKTOP_WIDTH } from '../../Utility/Breakpoints';
import { formatTags, handleResize, formatDeadlineDate, parseHtml, handleRediretInNewTab, handleRedirectHrefUrl } from '../../Utility/HelperFunctions';
import { toast } from 'react-toastify';
import FormatNumber, { floatFormatter } from '../../Utility/FormatNumber';
import Divider from '../Divider/Divider';
import { MAIN_CONFIG, enabledApplyButton, enabledPreviewApplyButton } from '../../../config/main';
import Check from '../../Components/SVG/Check';
import ShareIcon from '../../Components/SVG/ShareIcon';
import CTAButton from "../CTAButton/CTAButton";
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import SocialFacebook from '../../Components/SVG/SocialFacebook';
import SocialInstagram from '../../Components/SVG/SocialInstagram';
import SocialLinkedIn from '../../Components/SVG/SocialLinkedIn';
import SocialTwitter from '../../Components/SVG/SocialTwitter';
import SocialYouTube from '../../Components/SVG/SocialYouTube';
import SocialTiktok from '../../Components/SVG/SocialTiktok';
import SocialWebsite from '../../Components/SVG/SocialWebsite';
import GoogleLogin from 'react-google-login';
import FlashMessage from '../../Components/FlashMessage/Message';
import GoogleLogo from '../SVG/Google';
import LoginModal from '../../Components/LoginModal/LoginModal';
import { checkMarketPlace } from '../../Utility/HelperFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import isEmpty from 'lodash/isEmpty';
import cookies from 'next-cookies';
import ProgressCompleteCellType from '../Table/CellTypes/ProgressCompleteCellType/ProgressCompleteCellType';
import ReactTimeago from 'react-timeago';
import TagManager from 'react-gtm-module'
import SocialDiscord from '../SVG/SocialDiscord';
import ViewUrlModal from '../ViewUrlModal/ViewUrlModal';
import ImagePlaceholder from '../SVG/ImagePlaceholder';
import { API_CONFIG } from '../../../config/api';
import AccessCodeModal from '../Application/AccessCodeModal/AccessCodeModal';
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';
import CTAAnchorTag from '../CTAAnchorTag/CTAAnchorTag';
import CTAImageTag from '../CTAImageTag/CTAImageTag';

const API = new KaleidoscopeAPI({});
const { USER_CONTEXT, USER_ROLE_CONTEXT } = MAIN_CONFIG;
/**
 *
 * @param {Object} scholarship
 * @param {String} color - TODO: Remove this prop since it should be coming from scholarship.account
 * @returns {JSX.Element}
 */
const ScholarshipBody = ({ setModalState, scholarship, secondaryColor, color, account, userContext, applicationStep, previewApplyNow, handleClickApplyNow, setLoading, showModal, appliedApplication, checkEnableButton, autoApplyScholarship }) => {
    const router = useRouter()
    // TODO: Update this component to use data coming from API rather than hard-coded
    // const formattedDate = scholarship.deadline_date_time__c ? DateTime.fromISO(scholarship.deadline_date_time__c).toFormat('MMM d') : '';
    const getFormattedDate = (dateTime) => {
        return dateTime ? formatDeadlineDate(new Date(dateTime)) : '';
    }
    const getFloatNumber = (number) => {
        return FormatNumber(floatFormatter(number))
    }

    const formattedDate = getFormattedDate(scholarship.deadline_date_time);
    const formattedOpenDate = getFormattedDate(scholarship.open_date);
    const formattedAward = getFloatNumber(scholarship.scholarship_fund_amount__c);
    const [isMobile, setIsMobile] = useState(false);
    const [updateAlert, setUpdateAlert] = useState(false);
    const programStatus = ['Prototype', 'Accepting Applications']
    const getColor = (color) => {
        return color ? color.toLowerCase() : ''
    }
    const resize = () => handleResize(setIsMobile, window.innerWidth < DESKTOP_WIDTH);
    const convertedBackgroundColor = getColor(color) === "#ffffff" ? MAIN_CONFIG.COLORS.fillGreen : color;

    useEffect(() => {
        sessionStorage.removeItem('previousPath')
        setIsMobile(window.innerWidth < DESKTOP_WIDTH);
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    const getParentScholarship = () => {
        return scholarship.parent_scholarship_sfid && userContext === USER_CONTEXT.LOGGED_OUT
    }
    const parentScholarship = getParentScholarship()
    const showDefaultMessage = account.scholarship_restriction_message__c || "The General Application must be completed prior to beginning this form"
    useEffect(() => {

        if (parentScholarship) {
            const getAlert = () => (
                <SweetAlert
                    warning
                    confirmBtnCssClass="cta-button CTAWhite tertiary"
                    onConfirm={() => redirectToParentScholarship()}
                    id="copied-clipboard"
                    confirmBtnText="Okay"
                    title="Oops!"
                >
                    
                <p>{parseHtml(showDefaultMessage)}  </p>
                    
                </SweetAlert>
            );
            setUpdateAlert(getAlert)
        }
    }, [parentScholarship]);

    const mapGradeLevels = (gradeLevels) => {
        const desiredOrder = ["Middle School", "H.S. Freshman", "H.S. Sophomore", "H.S. Junior", "H.S. Senior", "Undergrad", "Graduate"];
        // Split the received data by ';' and trim spaces, then sort it based on the desired order
        const sortedGradeLevels = gradeLevels.split(';').map(level => level.trim()).sort((a, b) => {
          return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
        });
        // Join the sorted grade levels back into a string
        return sortedGradeLevels.join(', ');
    };

    const redirectToGeneral = () => {
        setLoading(true);
        handleRedirectHrefUrl(`${scholarship.general_scholarship_sfid ? '/scholarships/' + scholarship.general_scholarship_sfid : '/'}`)
        setUpdateAlert(false)
    }
    const redirectToParentScholarship = () => {
        setLoading(true);
        handleRedirectHrefUrl(`${scholarship.parent_scholarship_sfid ? '/scholarships/' + scholarship.parent_scholarship_sfid : '/'}`)
        setUpdateAlert(false)
    }
    const viewUrl = async (e, report_share_url) => {
        e.preventDefault();
        setModalState({
            visible: true,
            className: 'share-url-modal',
            content: <ViewUrlModal hideClose={false} ModalTitle='Share Program' handleClose={hideModal} url={report_share_url} />
        });
    }

    const redirectToDashboard = () => {
        setLoading(true);
        window.location.href = '/';
        setUpdateAlert(false)
    }

    const handleClickApply = (e) => {
        // localStorage.setItem('secondaryColor', secondaryColor)
        // const isApplicable =  schList.length > 0 ? (schList?.indexOf(country) < 0) :  false;


        if (!scholarship.is_user_eligible_for_next_step) {
            // if ((!scholarship.is_user_eligible_for_next_step) /*|| (schList.length > 0 && (schList?.indexOf(country)) < 0)*/) {
            const getAlert = () => (
                <SweetAlert
                    warning
                    confirmBtnCssClass="cta-button CTAWhite tertiary"
                    onConfirm={() => redirectToGeneral()}
                    id="copied-clipboard"
                    confirmBtnText="Okay"
                    title="Oops!"
                >

                    <p>{parseHtml(showDefaultMessage)}</p>
                </SweetAlert>
            );
            setUpdateAlert(getAlert)
        } else {
            if (scholarship.allow_multiple_applications__c && appliedApplication?.length > 0) {
                setUpdateAlert(null)
                showModal(e)
            } else {
                if (appliedApplication?.length > 0) {
                    setLoading(true)
                    let URL = `/application/${scholarship.sfid || scholarship.heroku_id__c}/${appliedApplication[0].sfid || appliedApplication[0].heroku_id__c}`
                    if (appliedApplication[0].status__c === 'Submitted' || appliedApplication[0].status__c === 'In Review Board') {
                        URL = `/application/${scholarship.sfid || scholarship.heroku_id__c}/${appliedApplication[0].sfid || appliedApplication[0].heroku_id__c}?action=completed`
                    }
                    router.push(URL, undefined, { shallow: true })
                } else {
                    setLoading(true)
                    // window.gtag("event", "Begin", {
                    //     event_category: "scholarship",
                    //     event_label: scholarship.name,
                    //     value: new Date()
                    // })

                    const data = {
                        event: 'Begin',
                        yourData: {
                            event_category: "scholarship",
                            event_label: scholarship.name,
                            value: new Date()
                        }
                    };
                    TagManager.dataLayer(data);

                    router.push(`/apply/${scholarship.sfid}/${applicationStep.sfid || ''}`, undefined, { shallow: true })
                }
            }
        }
    }

    const redirectToHome = () => {
        const getAlert = () => (
            <SweetAlert
                warning
                confirmBtnCssClass="cta-button CTAWhite tertiary"
                onConfirm={() => redirectToDashboard()}
                id="copied-clipboard"
                confirmBtnText="Okay"
                title="Oops!"
            >
                <p>Please allow Kaleidoscope to access your location.</p>
            </SweetAlert>
        );
        setUpdateAlert(getAlert)
    }

    const redirectFuc = async () => {
        setLoading(true)
        const redirectUrl = scholarship.enable_sso__c && account.enable_sso__c ? `/scholarships/${scholarship.sfid}` : `/apply/${scholarship?.sfid}/${applicationStep?.sfid || ''}`;
        localStorage.setItem('redirectURL', redirectUrl);
        
        let isUserLoggedOut = userContext === USER_CONTEXT.LOGGED_OUT
        if (isUserLoggedOut ? (scholarship.enable_sso__c && account.enable_sso__c) : (account.enable_sso__c && scholarship.redirect_user_to_idp)) {
            if (userContext === USER_CONTEXT.APPLICANT) {
                document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            }
            handleRedirectHrefUrl(`${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}/sso/init?idp_uniq_id=${scholarship.account__c}`)
        } else if(scholarship.form_type__c === "Donation Form"){
            router.push(redirectUrl, undefined, { shallow: true })
        } else {
            sessionStorage.setItem("redirectApply", scholarship?.name)
            router.push(`${MAIN_CONFIG.URLS.LOGIN}`, undefined, { shallow: true })
        }
    }

    const redirectFunction = (e) => {
        if (scholarship?.ready_to_apply__c) {
            setLoading(false)
            const getAlert = () => (
                <SweetAlert
                    confirmBtnCssClass="cta-button CTAWhite tertiary"
                    id="copied-clipboard"
                    confirmBtnText="Okay"
                    customButtons={
                        <div className='action-footer'>
                            <button onClick={() => setUpdateAlert(null)} className='cta-button CTABlack cta-button__text' tabIndex={0}>Cancel</button>
                            <button onClick={(e) => {
                                (userContext === USER_CONTEXT.LOGGED_OUT || account.enable_sso__c && scholarship.redirect_user_to_idp) ? redirectFuc() : handleClickApply(e)
                            }}
                                className='cta-button CTAWhite tertiary cta-button__text'>Okay</button>
                        </div>
                    }
                >
                     <div className="scholarship-body__feature">
                            <div className="scholarship-body__feature-body">
                                <p className="BodyDefaultRegularBlack">
                                    <div className="html-from-salesforce">
                                        {parseHtml(scholarship?.ready_to_apply__c)}
                                    </div>
                                </p>
                            </div>
                        </div>
                </SweetAlert>
            );
            setUpdateAlert(getAlert)
        }
    }

    const handleAccessCodeCheck = async () => {
        if (scholarship.access_code_to_edit_after_deadline__c) {
            setModalState({
                visible: true,
                id: 'access-code-modal-content',
                class: 'application_step_modal',
                content: <AccessCodeModal
                    scholarshipId={scholarship.sfid}
                    setLoading={setLoading}
                    redirectURL={`/apply/${scholarship.sfid}/${applicationStep.sfid || ''}`}
                    setModalState={setModalState}
                    redirectFunction={redirectFunction}
                    readyToApply={scholarship.ready_to_apply__c}
                    isRedirectSSO={userContext === USER_CONTEXT.LOGGED_OUT || (account.enable_sso__c && scholarship.redirect_user_to_idp)}
                    redirectFuc={redirectFuc}
                    handleClickApply={handleClickApply}
                    allowMultiple={scholarship?.allow_multiple_applications__c}
                    accessCodePopupDescription={scholarship?.access_code_popup_description__c}
                    accessCodeFailureMessage={scholarship?.access_code_failure_message__c}
                    accessCodeSuccessMessage={scholarship?.access_code_success_message__c}
                />
            });
        }
    }

    const handleLoginWithGoogle = async (payload) => {
        const response = await API.googleLogin(payload)
        setLoading(true)
        if (response.user) {

            let role = []
            role = response.user.role;
            if (role?.length > 1) {
                // role = userDetail.role.filter(r => r !== 'Admin')
                setModalState({
                    visible: true,
                    className: 'login-role-application__List',
                    content: <LoginModal handleClose={hideModal} setLoading={setLoading} roles={role} handleLogin={selectRoleLogin} authDetail={response.user} />
                });
                setLoading(false)
            } else {
                selectRoleLogin(role[0], response.user)
            }

        } else {
            toast.error(<FlashMessage message={"response is not correct!!"} />);
            setLoading(false)
        }

    }

    /**
     * remove the modal content and hide the modal.
     */
    const hideModal = (e) => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }


    const selectRoleLogin = (selectedRole, auth) => {
        setLoading(true)
        let userRoleArg = [];
        let roleContext = ''
        USER_ROLE_CONTEXT.map(role => {
            if (role.role === selectedRole) {
                userRoleArg.push(role);
            }
        })
        roleContext = userRoleArg?.length > 0 ? userRoleArg[0] : ''
        if (roleContext) {

            hideModal()
            document.cookie = `token=${auth.token};path=/`;
            document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
            delete auth.avatar
            document.cookie = `user=${JSON.stringify(auth)};path=/`;

            setTimeout(() => {
                if (roleContext.context === "APPLICANT") {
                    handleRedirectHrefUrl(`/scholarships/${scholarship.sfid}`)
                } else {
                    window.location.href = checkMarketPlace();
                }
            }, 5000);
        } else {
            const getAlert = () => (
                <SweetAlert
                    warning
                    confirmBtnCssClass="cta-button CTAWhite tertiary"
                    onConfirm={() => setUpdateAlert(false)}
                    id="copied-clipboard"
                >
                    <p className="CTAGrey">{selectedRole} role not supportted. </p>
                </SweetAlert>
            );
            setUpdateAlert(getAlert())
            hideModal()
        }
    }

    const responseGoogle = (response) => {
        if (!response) {
            toast.error(MAIN_CONFIG.GOOGLE_AUTH_ERROR)
        } else {
            handleLoginWithGoogle(response)
        }
    }

    const failureResponseGoogle = (response) => {
        console.log(response, "failureResponseGoogle")
    }

    const handleButtonClick = async (e) => {
        if (scholarship?.access_code_to_edit_after_deadline__c) {
            handleAccessCodeCheck();
        }
        else if (scholarship?.ready_to_apply__c) {
            redirectFunction(e);
        } else if (
            userContext === USER_CONTEXT.LOGGED_OUT ||
            (account.enable_sso__c && scholarship.redirect_user_to_idp)
        ) {
            redirectFuc();
        } else {
            handleClickApply(e);
        }
    }

    let showDonationArr = scholarship.donation_information_visibility__c?.split(";")

    let donationForm = scholarship.form_type__c === "Donation Form"

    const remainingAmount = scholarship.scholarship_fund_amount__c - scholarship.total_donation_received__c


    /**
     * Render the card which floats to the right side of the screen showing award amount, apply button, sponsor, etc.
     * @returns {JSX.Element}
     */

    const renderLogo = () => {
        const logoUrl = scholarship.scholarshiplogourl__c || scholarship.scholarship_header_logo_url__c;
        return (
            <div className='small-card-logo'>
                {logoUrl ? (
                    <CTAImageTag data={logoUrl} title={`${scholarship.name} Logo`} />
                ) : (
                    <ImagePlaceholder
                        className=""
                        ariaLabel="Scholarship Logo"
                        ariaLabelDescription=""
                    />
                )}
            </div>
        );
    };

    const renderDonationsProgressBar = () => {
        const { donation_progress__c, total_donation_received__c } = scholarship;
        if (scholarship.form_type__c === "Donation Form") {
            return (
                <div className="scholarship-card__donations-progress-bar">
                    {showDonationArr?.includes("Show Fund Progress") && (
                        <div className='scholarship-card__donations-progress-bar__progress-container'>
                            <ProgressCompleteCellType
                                value={donation_progress__c !== null ? donation_progress__c + "%" : 0 + "%"}
                                barBackgroundColor="barBackgroundColor"
                                barProgressColor={primaryColorConvert == '#ffffff' ? '#000000' : primaryColorConvert}
                            />
                        </div>
                    )}
                    {showDonationArr?.includes('Show Fund Amount') && (
                        <div className='scholarship-card__donations-progress-bar__donations-amount-status scholarship__landing__page BodySmallRegular'>
                            {!donationForm ? (
                                <span className="">${total_donation_received__c !== null ? total_donation_received__c : 0}</span>
                            ) : (
                                <span>$ {total_donation_received__c !== null ? FormatNumber(floatFormatter(total_donation_received__c)) : 0} DONATED</span>
                            )}
                            {donationForm && remainingAmount > 0 && <span> ${FormatNumber(floatFormatter(remainingAmount))} REMAINING</span>}
                        </div>
                    )}
                </div>
            )
        }
    };

    const renderLastDonationTime = () => {
        if (showDonationArr?.includes('Show Last Donation Time') && scholarship.last_donation_received_at__c !== null && donationForm) {
            return (
                <div className='scholarship-card__donations-progress-bar__last-donations BodySmallRegular'>
                    <span className="">Last donation</span> <ReactTimeago date={DateTime.fromISO(scholarship.last_donation_received_at__c)} />
                </div>
            )
        }
    }
    const renderDeadlineDate = () => {
        if (scholarship.status__c === 'Accepting Applications') {
            if (scholarship.show_open_date_in_card__c && checkEnableButton()) {
                return formattedOpenDate ? `Open: ${formattedOpenDate}` : '';
            } else if (!scholarship.deadline_date_time) {
                return scholarship.form_type__c !== "Donation Form" ? 'Accepting Applications' : 'Accepting Donations';
            } else {
                return formattedDate ? `Submit by ${formattedDate}` : '';
            }
        }
    }

    const getConvertedColor = (primaryColorConvert, color) => primaryColorConvert === '#ffffff' ? '#000000' : color;

    const renderAmountSection = () => {
        const shouldRenderAmount = scholarship.show_fund_amount__c &&
            (scholarship.maximum_fund_amount__c ||
                scholarship.minimum_fund_amount__c ||
                scholarship.scholarship_fund_amount__c > 0);

        if (!shouldRenderAmount) {
            return null;
        }

        const renderAmount = () => {
            if (scholarship.scholarship_fund_amount__c) {
                return (
                    <span className="scholarship-body__card-amount H6Desktop"
                        style={{ 'color': getConvertedColor(primaryColorConvert, color) }}>
                        {donationForm ? "Goal:" : "Award Amount:"} {scholarship.show_currency_symbol__c && '$'} {formattedAward}
                    </span>
                );
            } else if (scholarship.form_type__c === "Donation Form") {
                return (
                    <span className="scholarship-body__card-amount H6Desktop"
                        style={{ 'color': getConvertedColor(primaryColorConvert, color) }}>
                        {scholarship.show_currency_symbol__c && '$'} {"0"} <span>donate</span>
                    </span>
                );
            } else {
                const minAmount = scholarship.minimum_fund_amount__c ? `$${FormatNumber(scholarship.minimum_fund_amount__c)}` : '';
                const maxAmount = scholarship.maximum_fund_amount__c ? `$${FormatNumber(scholarship.maximum_fund_amount__c)}` : '';

                return (
                    <span className="scholarship-body__card-amount H6Desktop"
                        style={{ 'color': getConvertedColor(primaryColorConvert, color) }}>
                        <span className=''>Award Amount:</span>&nbsp;
                        {(scholarship.show_currency_symbol__c && scholarship.minimum_fund_amount__c) && minAmount}
                        {scholarship.minimum_fund_amount__c && scholarship.maximum_fund_amount__c && ' - '}
                        {scholarship.maximum_fund_amount__c && scholarship.show_currency_symbol__c && maxAmount}
                    </span>
                );
            }
        };

        return (
            <div className="scholarship-body__card-top">
                <span>{renderAmount()}</span>
                <div className="scholarship-body__card-save">
                    {!donationForm ?
                        <img src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/classic/o67dsf2m2thfpxmvjngp.svg" alt='Award logo' style={{ width: '40px' }} /> :
                        <img src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/classic/ysw2vyroxgp4zfgn4xjf.svg" alt='donate Logo' style={{ width: '40px' }} />
                    }
                </div>
            </div>
        );
    };

    const customStyles = {
        backgroundColor:  convertedBackgroundColor,
        color: getConvertedColor(primaryColorConvert, '#ffffff'),
        borderColor: getConvertedColor(primaryColorConvert, '#ffffff'),
        border: '1px solid'
    };

    const renderButtonText = (scholarship) => {
        if (checkEnableButton()) {
            return 'Coming soon';
        } else if (scholarship.form_type__c === "Donation Form" && !programStatus.includes(scholarship.status__c)) {
            return 'Form Closed'
        } else if (!programStatus.includes(scholarship.status__c)) {
            return 'Application Closed';
        } else if (scholarship.status__c === 'Prototype') {
            return 'Testing'
        } else if (scholarship.form_type__c === 'Donation Form') {
            return 'Donate'
        } else {
            return 'Begin'
        }
    }

    const renderExternalProgramBegin = () => {
        if (checkEnableButton() || !programStatus.includes(scholarship.status__c)) {
            return (
                <CTAButton
                    className="scholarship-body__card-cta CTA color__white"
                    type="tertiary"
                    disabled={true}
                    style={{ backgroundColor: color }}
                >
                    {renderButtonText(scholarship)}
                </CTAButton>
            )
        } else {
            return (
                <CTAAnchorTag handleClick={() => handleRediretInNewTab(scholarship.carousel_card_click_url__c)}>
                    <CTAButton
                        className="scholarship-body__card-cta CTA color__white"
                        type="tertiary"
                        disabled={!enabledApplyButton.includes(scholarship.status__c) || scholarship.restricted_access__c}
                        style={customStyles}
                    >
                        {scholarship.status__c === 'Prototype' ? 'Testing' : 'Begin'}
                    </CTAButton>
                </CTAAnchorTag>
            )
        }
    }

    const renderButtonContainer = () => {
        if (scholarship.form_type__c === "External Program") {
            return renderExternalProgramBegin();
        } else if (USER_CONTEXT.LOGGED_OUT == userContext && (scholarship.enable_google_authentication__c)) {
            return (
                <GoogleLogin
                    clientId={process.env.NEXT_PUBLIC_GOOGLE_APP_KEY}
                    buttonText="Begin with Google"
                    onSuccess={responseGoogle}
                    onFailure={failureResponseGoogle}
                    disabled={scholarship.restricted_access__c}
                    render={renderProps => (
                        <CTAButton
                            className="scholarship-body__card-cta CTA color__white begin-with-google"
                            type='tertiary'
                            onClick={renderProps.onClick}
                            style={customStyles}
                        >
                            <GoogleLogo width="25" height="25" />
                            Begin with Google</CTAButton>
                    )}
                />
            )
        } else if (previewApplyNow) {
            return (
                <CTAButton
                    className="scholarship-body__card-cta CTA color__white"
                    onClick={(e) => handleClickApplyNow()}
                    type="tertiary"
                    disabled={isEmpty(applicationStep) || !enabledPreviewApplyButton.includes(scholarship.status__c) || scholarship.restricted_access__c}
                    style={{ backgroundColor: color }}
                >
                    Begin
                </CTAButton>
            )
        } else if (checkEnableButton()) {
            return (
                <CTAButton
                    className="scholarship-body__card-cta CTA color__white"
                    type="tertiary"
                    disabled={true}
                    style={{ backgroundColor: color }}

                >
                    {renderButtonText(scholarship)}
                </CTAButton>
            )
        } else if (!programStatus.includes(scholarship.status__c)) {
            return (
                <CTAButton
                    className="scholarship-body__card-cta CTA color__white"
                    type="tertiary"
                    disabled={!(scholarship.status__c === 'Closed' && scholarship.access_code_to_edit_after_deadline__c) || scholarship.restricted_access__c}
                    style={{ backgroundColor: convertedBackgroundColor }}
                    onClick={(e) => handleButtonClick(e)}
                >
                    {renderButtonText(scholarship)}
                </CTAButton>
            )
        } else {
            return (
                <CTAButton
                    className="scholarship-body__card-cta CTA color__white"
                    onClick={(e) => handleButtonClick(e)}
                    type="tertiary"
                    disabled={isEmpty(applicationStep) || !enabledApplyButton.includes(scholarship.status__c) || autoApplyScholarship || scholarship.restricted_access__c}
                    style={customStyles}
                >
                    {renderButtonText(scholarship)}
                </CTAButton>
            )
        }
    }

    const renderAwardCard = () => (
        !autoApplyScholarship &&
        <div className="scholarship-body__card-wrapper">
            <div className={`scholarship-body__card ${scholarship.form_type__c === "Donation Form" && window.screen.width < 500 && "mobile-view"}`} >
                <div className='scholarship-body__card-bar' style={{ 'background-color': primaryColorConvert == '#ffffff' ? '#000000' : color }}></div>
                <div className='scholarship-body__card-box'>
                    {renderLogo()}
                    {scholarship.name && <h1 className="scholarship-header__name small-card-title" style={{ 'color': '#00000' }}>{scholarship.name}</h1>}
                    {renderAmountSection()}
                    <div className="scholarship-body__card-meta">
                        {renderDonationsProgressBar()}
                    </div>
                    {renderLastDonationTime()}

                    <div className="scholarship-body__card-deadline">
                        <span className="scholarship-body__card-deadline-label">
                            {renderDeadlineDate()}
                        </span>
                    </div>
                    <div className='share-and-status-button-container'>
                        <div className='share-container' onClick={(e) => viewUrl(e, getCurrentURL())} tabIndex={0} role='button' onKeyDown={(e) => handleKeyDown(e, () => viewUrl(e, getCurrentURL()))}>
                            <ShareIcon ariaLabel="Share Icon" ariaLabelDescription='Click Here to get the program url' /> Share
                        </div>
                        <div className='button-container'>
                            {renderButtonContainer()}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

    /**
     * Render tags for scholarship which comes from scholarship_category__c
     * @returns {unknown[]}
     */
    const renderTags = () => {
        const tags = formatTags(scholarship.scholarship_category__c);
        return tags.map((tag, i) => (
            <span key={i} className="scholarship-body__tag BodySmallMediumBlack">{tag}</span>
        ));
    };

    /**
     * Render tags for scholarship which comes from scholarship_category__c
     * @returns {unknown[]}
     */
    const renderQuestions = () => {
        return scholarship.essay_questions_available__c ? scholarship.essay_questions.map((row, i) => {
            return (
                <li className="scholarship-body__question">
                    <span className="scholarship-body__question-label BodyDefaultBold">
                        {i + 1}. {row.label__c}
                    </span>
                    <span className="scholarship-body__question-words color__black">{row.word_count__c} words</span>
                </li>
            )
        })
            :
            <span className="scholarship-body__sponsor-link CTA color__dark-grey">No Data Found</span>
    };


    /**
     * Render the introduction of scholarship
     * @returns {JSX.Element}
     */
    const renderIntroductionDescription = () => {
        return <div className="html-from-salesforce">{parseHtml(scholarship.introduction__c)}</div>
    };

    function getCurrentURL() {
        return window.location.href
    }

    /**
     * Render the description of scholarship
     * @returns {JSX.Element}
     */
    const renderDescription = () => {
        return <div className="html-from-salesforce">{parseHtml(scholarship.description__c)}</div>
    };
    scholarship.minimum_gpa__c = scholarship.minimum_gpa__c || 0
    const tags = formatTags(scholarship.scholarship_category__c)

    const primaryColorConvert = getColor(color)

    const renderVideoSection = () => {
        if (scholarship.embed_video_url__c) {
            return <div id="scholarship-video">{parseHtml(scholarship.embed_video_url__c)}</div>
        }
    }
    const renderEssayQuestions = () => {
        let shouldRender = scholarship.essay_questions_available__c &&
            (scholarship.essay_questions || scholarship.essay_questions?.length > 0);
        if (shouldRender) {
            return (
                <div className="scholarship-body__feature">
                    <div className="scholarship-body__feature-top">
                        <div className="scholarship-body__feature-top__icon-with-title">
                            <div className="">
                                {/* <Paper
                                    color={secondaryColor}
                                    ariaLabel="Essay Questions"
                                    ariaLabelDescription=""
                                /> */}
                            </div>
                            <div className="">
                                <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}>Essay Questions</h2>
                            </div>
                        </div>
                    </div>
                    <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                    <ol className="scholarship-body__feature-body">
                        {renderQuestions()}
                    </ol>
                </div>
            )
        }
        return null
    }
    return (
        <div className="scholarship-body bg-white">
            {updateAlert}
            <div className="scholarship-body__content">
                <div className="scholarship-body__leading">
                    {!isMobile &&
                        <div className="scholarship-application-accepting-status">
                            {/* <span className="status-value">{renderTop()}</span>  */}
                            <span className='share-application' onClick={(e) => viewUrl(e, getCurrentURL())} tabIndex={0} role='button' onKeyDown={(e)=>handleKeyDown(e,()=>viewUrl(e, getCurrentURL()))}><ShareIcon ariaLabel="Share Icon" ariaLabelDescription='Click Here to get the program url'/> Share</span>
                        </div>
                    }
                    {scholarship.name && <h1 className="scholarship-header__name color__black" style={{ 'color': '#00000' }}>{scholarship.name}</h1>}
                    {tags?.length > 0 &&
                        <>
                            <div className="scholarship-body__feature-top">
                                <div className="scholarship-body__feature-top__icon-with-title">
                                    <div className="">
                                        {/* <Folder color={secondaryColor} width={40} height={40} /> */}
                                    </div>
                                    <div className="">
                                        <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}>Application Categories</h2>
                                    </div>
                                </div>

                            </div>
                            <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                            <div className="scholarship-body__feature-body">

                            </div>

                            <div className="scholarship-body__tags">
                                {renderTags()}
                            </div>
                        </>
                    }


                    {renderVideoSection()}


                    {(scholarship.introduction__c) &&
                        <>
                            <div className="scholarship-body__feature-top">
                                <div className="scholarship-body__feature-top__icon-with-title">
                                    <div className="">
                                        {/* <Dashboard
                                            color={secondaryColor}
                                            width={40}
                                            height={40}
                                            ariaLabel="Introduction"
                                            ariaLabelDescription=""
                                        /> */}
                                    </div>
                                    <div className="">
                                        <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}>Introduction</h2>
                                    </div>
                                </div>
                            </div>
                            <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                            <div className="scholarship-body__feature-body">

                            </div>

                            <div className="scholarship-body__description BodyDefaultRegularBlack">
                                {renderIntroductionDescription()}
                            </div>
                        </>
                    }


                    {(scholarship.description__c) &&
                        <>
                            <div className="scholarship-body__feature-top">
                                <div className="scholarship-body__feature-top__icon-with-title">
                                    <div className="">
                                        {/* <Book
                                            color={secondaryColor}
                                            ariaLabel="Description"
                                            ariaLabelDescription=""
                                        /> */}
                                    </div>
                                    <div className="">
                                        <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}>Description</h2>
                                    </div>
                                </div>
                            </div>
                            <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                            <div className="scholarship-body__feature-body">

                            </div>

                            <div className="scholarship-body__description BodyDefaultRegularBlack">
                                {renderDescription()}
                            </div>
                        </>
                    }

                    {!autoApplyScholarship && isMobile && (
                        <div className="scholarship-body__trailing">
                            {renderAwardCard()}
                        </div>
                    )}


                    {!(!scholarship.minimum_gpa__c && !scholarship.citizenship__c && !scholarship.grade_level__c && !scholarship.state_eligibility__c) &&

                        <div className="scholarship-body__feature">
                            <div className="scholarship-body__feature-top">
                                <div className="scholarship-body__feature-top__icon-with-title">
                                    <div>
                                        {/* <Basketball
                                            color={secondaryColor}
                                            ariaLabel="Eligibility"
                                            ariaLabelDescription=""
                                        /> */}
                                    </div>
                                    <div>
                                        <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}><div>Eligibility</div></h2>
                                    </div>
                                </div>
                                <div className="">
                                    {scholarship.is_user_eligible && <div><Check /> <span className="EyebrowGreen">Eligible</span></div>}
                                </div>
                            </div>

                            <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                            <ul className="scholarship-body__feature-body">
                                {scholarship.minimum_gpa__c > 0 &&
                                    <li className="scholarship-body__feature-single">
                                        <span className="scholarship-body__gpa-circle" style={{ 'borderColor': secondaryColor, boxShadow: `-1px 1px 0 0 ${secondaryColor}` }} />
                                        <span className="scholarship-body__feature-label BodySmallRegularBlack">
                                            GPA: {scholarship.minimum_gpa__c.toFixed(1)}
                                        </span>
                                    </li>
                                }

                                {scholarship.satisfactory_eligibility__c &&
                                    <li className="scholarship-body__feature-single">
                                        <span className="scholarship-body__gpa-circle" style={{ 'borderColor': secondaryColor, boxShadow: `-1px 1px 0 0 ${secondaryColor}` }} />
                                        <span className="scholarship-body__feature-label BodySmallRegularBlack">
                                            {scholarship.satisfactory_eligibility__c}
                                        </span>
                                    </li>
                                }

                                {scholarship.state_eligibility__c &&
                                    <li className="scholarship-body__feature-single">
                                        <span className="scholarship-body__gpa-circle" style={{ 'borderColor': secondaryColor, boxShadow: `-1px 1px 0 0 ${secondaryColor}` }} />
                                        <span className="scholarship-body__feature-label BodySmallRegularBlack">
                                            Residence: {scholarship.state_eligibility__c.includes(";") ? scholarship.state_eligibility__c.replace(/[;]/g, ", ") :
                                                scholarship.state_eligibility__c}
                                        </span>
                                    </li>
                                }
                                {scholarship.citizenship__c &&
                                    <li className="scholarship-body__feature-single">
                                        <span className="scholarship-body__gpa-circle" style={{ 'borderColor': secondaryColor, boxShadow: `-1px 1px 0 0 ${secondaryColor}` }} />
                                        <span className="scholarship-body__feature-label BodySmallRegularBlack">
                                            Citizenship: {scholarship.citizenship__c.includes(";") ? scholarship.citizenship__c.replace(/[;]/g, ", ") :
                                                scholarship.citizenship__c}
                                        </span>
                                    </li>
                                }

                                {scholarship.grade_level__c &&
                                    <li className="scholarship-body__feature-single">
                                        <span className="scholarship-body__gpa-circle" style={{ 'borderColor': secondaryColor, boxShadow: `-1px 1px 0 0 ${secondaryColor}` }} />
                                        <span className="scholarship-body__feature-label BodySmallRegularBlack">
                                    Grade Level: {scholarship.grade_level__c.includes(";")
                                        ? mapGradeLevels(scholarship.grade_level__c)
                                        : scholarship.grade_level__c}
                                    </span>
                                </li>
                                }

                            </ul>
                        </div>
                    }

                    {scholarship.qualifications__c &&
                        <div className="scholarship-body__feature">
                            <div className="scholarship-body__feature-top">
                                <div className="scholarship-body__feature-top__icon-with-title">
                                    <div className="">
                                        {/* <TestTubes
                                            color={secondaryColor}
                                            ariaLabel="Selection Criteria"
                                            ariaLabelDescription=""
                                        /> */}
                                    </div>
                                    <div className="">
                                        <h2 className="scholarship-body__feature-name H3Desktop color__green" style={{ 'color': secondaryColor }}>Selection Criteria</h2>
                                    </div>
                                </div>

                            </div>
                            <Divider className="scholarship-body__feature-divider" solid={false} color='#e6e9d7' />
                            <div className="scholarship-body__feature-body">
                                <p className="BodyDefaultRegularBlack">
                                    <div className="html-from-salesforce">{parseHtml(scholarship.qualifications__c)}</div>
                                    {!scholarship.qualifications__c &&
                                        <span className="scholarship-body__sponsor-link CTA color__dark-grey">No Data Found</span>
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    {renderEssayQuestions}
                </div>

                {isMobile ? "" : <div className="scholarship-body__trailing">{renderAwardCard()}</div>}
            </div>

            <Divider solid={true} color={MAIN_CONFIG.COLORS.fillLightGrey} />
            {!scholarship.remove_about_the_sponsor__c &&
                <div className='scholarship-body__sponsor-main'>
                    <div className="scholarship-body__sponsor">
                        {/* <h2 className="scholarship-body__sponsor-heading H2Desktop color__green" style={{ 'color': secondaryColor }}>{account.about_the_sponsor__c || "About the Sponsor"}</h2> */}
                        <div className="scholarship-body__sponsor-content">
                            {/* <div className="scholarship-body__sponsor-img-wrapper" style={{ backgroundColor: color }}> */}
                            <div className="scholarship-body__sponsor-img-wrapper">
                                <CTAImageTag data={scholarship.about_the_sponsor_image__c || account.sponsor_image__c || "/static/images/sponsorplaceholder.jpg"}
                                    title={`${account.name} banner image`} className="scholarship-body__sponsor-img" />
                            </div>
                            <div className="scholarship-body__sponsor-info">
                                <CTAImageTag data={scholarship.sponsor_logo_image__c || account.account_logo_url__c} title={`${account.name} logo`} className="scholarship-body__sponsor-logo" />

                                <h2 className="scholarship-body__sponsor-heading H2Desktop color__green" style={{ 'color': secondaryColor }}>{account.about_the_sponsor__c || "About the Sponsor"}</h2>

                                <p className="scholarship-body__sponsor-description BodyDefault">
                                    {(scholarship.about_the_sponsor_description__c && parseHtml(scholarship.about_the_sponsor_description__c)) || parseHtml(account.about_the_sponsor_description__c)}
                                </p>
                                <div className="scholarship-body__sponsor-bottom">
                                    {scholarship.show_awarded_to_applicants__c &&
                                        <div className="scholarship-body__sponsor-highlight">
                                            <span
                                                className="scholarship-body__sponsor-highlight-amount H2Desktop color__green" style={{ 'color': secondaryColor }}>$ {account && account.all_time_awarded_amount && account.all_time_awarded_amount.toLocaleString('en') || 0}</span>
                                            <span className="scholarship-body__sponsor-highlight-label">Awarded to applicants</span>
                                        </div>
                                    }
                                    {scholarship.show_scholarships_awarded__c &&
                                        <div className="scholarship-body__sponsor-highlight">
                                            <span
                                                className="scholarship-body__sponsor-highlight-amount H2Desktop color__green" style={{ 'color': secondaryColor }}>{account && account.total_applicants__c && account.total_applicants__c.toLocaleString('en') || 0}</span>
                                            <span className="scholarship-body__sponsor-highlight-label">Scholarships Awarded</span>
                                        </div>
                                    }

                                </div>

                                <div className="scholarship-body__sponsor-social" >
                                    {account.follow_us_on_facebook__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_facebook__c)} style={{ 'borderColor': secondaryColor }}><SocialFacebook width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_instagram__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_instagram__c)} style={{ 'borderColor': secondaryColor }}><SocialInstagram width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_linkedin__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_linkedin__c)} style={{ 'borderColor': secondaryColor }}><SocialLinkedIn width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_twitter__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_twitter__c)} style={{ 'borderColor': secondaryColor }}><SocialTwitter width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_youtube__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_youtube__c)} style={{ 'borderColor': secondaryColor }}><SocialYouTube width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_tiktok__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_tiktok__c)} style={{ 'borderColor': secondaryColor }}><SocialTiktok width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_website__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_website__c)} style={{ 'borderColor': secondaryColor }}><SocialWebsite width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                    {account.follow_us_on_discord__c &&
                                        <CTAAnchorTag handleClick={() => handleRediretInNewTab(account.follow_us_on_discord__c)} style={{ 'borderColor': secondaryColor }}><SocialDiscord width={40} height={40} color={secondaryColor} className="scholarship-body__sponsor-social__single-item" /></CTAAnchorTag>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
};
export default ScholarshipBody

ScholarshipBody.propTypes = {
    setModalState: PropTypes.func,
    secondaryColor: PropTypes.string,
    color: PropTypes.string,
    userContext: PropTypes.string,
    account: PropTypes.shape({
        scholarship_restriction_message__c: PropTypes.string,
        enable_sso__c: PropTypes.bool,
        name: PropTypes.string,
        sponsor_image__c: PropTypes.string,
        account_logo_url__c: PropTypes.string,
        follow_us_on_discord__c: PropTypes.string,
        follow_us_on_website__c: PropTypes.string,
        follow_us_on_tiktok__c: PropTypes.string,
        follow_us_on_youtube__c: PropTypes.string,
        follow_us_on_twitter__c: PropTypes.string,
        follow_us_on_linkedin__c: PropTypes.string,
        follow_us_on_instagram__c: PropTypes.string,
        follow_us_on_facebook__c: PropTypes.string,
        total_applicants__c: PropTypes.number,
        about_the_sponsor_description__c: PropTypes.string,
        all_time_awarded_amount: PropTypes.number,
        about_the_sponsor__c: PropTypes.string
    }),
    applicationStep: PropTypes.shape({
        sfid: PropTypes.number
    }),
    previewApplyNow: PropTypes.bool,
    handleClickApplyNow: PropTypes.func,
    setLoading: PropTypes.func,
    showModal: PropTypes.func,
    appliedApplication: PropTypes.arrayOf(PropTypes.shape({
        heroku_id__c: PropTypes.any,
        sfid: PropTypes.any,
        status__c: PropTypes.string
    })),
    checkEnableButton: PropTypes.func,
    autoApplyScholarship: PropTypes.bool.isRequired,
    scholarship: PropTypes.shape({
        id: PropTypes.number,
        sfid: PropTypes.string,
        heroku_id__c: PropTypes.string,
        name: PropTypes.string,
        account__c: PropTypes.string,
        url_name__c: PropTypes.string,
        status__c: PropTypes.string,
        description__c: PropTypes.string,
        introduction__c: PropTypes.string,
        gender__c: PropTypes.string,
        ready_to_apply__c: PropTypes.string,
        blind_review__c: PropTypes.bool,
        preview_text__c: PropTypes.string,
        enable_sso__c: PropTypes.bool,
        pre_url_name__c: PropTypes.string,
        minimum_gpa__c: PropTypes.number,
        parent_scholarship_sfid: PropTypes.any,
        form_type__c: PropTypes.string,
        allow_donation_past_requested_amount__c: PropTypes.bool,
        total_donation_received__c: PropTypes.number,
        last_donation_received_at__c: PropTypes.any,
        donation_progress__c: PropTypes.any,
        donation_information_visibility__c: PropTypes.string,
        elements_program__c: PropTypes.bool,
        elements_review_board__c: PropTypes.bool,
        application_score_archive__c: PropTypes.bool,
        open_date: PropTypes.string,
        open_date__c: PropTypes.string,
        open_date: PropTypes.string,
        open_date__c: PropTypes.string,
        deadline_date: PropTypes.any,
        deadline_date__c: PropTypes.any,
        deadline_date_time: PropTypes.any,
        deadline_date_time__c: PropTypes.any,
        review_board_end_date: PropTypes.any,
        review_board_end_date__c: PropTypes.any,
        recommendation_due_date: PropTypes.any,
        recommendation_due_date__c: PropTypes.any,
        qualifications__c: PropTypes.string,
        qualification_text__c: PropTypes.string,
        total_disbursements__c: PropTypes.number,
        terms_and_conditions__c: PropTypes.string,
        day_remaining: PropTypes.number,
        application_limit__c: PropTypes.string,
        applicable_countries__c: PropTypes.string,
        scholarshiplogourl__c: PropTypes.string,
        minimum_fund_amount__c: PropTypes.any,
        maximum_fund_amount__c: PropTypes.any,
        scholarship_fund_amount__c: PropTypes.number,
        show_currency_symbol__c: PropTypes.bool,
        show_fund_amount__c: PropTypes.bool,
        total_number_of_applicants__c: PropTypes.any,
        target_number_of_applicants__c: PropTypes.any,
        is_term_condition_accepted__c: PropTypes.bool,
        satisfactory_eligibility__c: PropTypes.string,
        autoapply_scholarship__c: PropTypes.bool,
        show_nominee_name_in_card__c: PropTypes.bool,
        enable_copyleaks_scan__c: PropTypes.bool,
        header_scholarship_title__c: PropTypes.string,
        day_of_the_week_visibility__c: PropTypes.bool,
        listing_scholarship_title__c: PropTypes.string,
        main_page_scholarship_title__c: PropTypes.string,
        location_zipcode__c: PropTypes.any,
        lastmodifieddate: PropTypes.string,
        show_awarded_to_applicants__c: PropTypes.bool,
        show_scholarships_awarded__c: PropTypes.bool,
        recommendation_description__c: PropTypes.string,
        with_recommendations__c: PropTypes.bool,
        scholarship_category__c: PropTypes.string,
        grade_level__c: PropTypes.string,
        state_eligibility__c: PropTypes.string,
        citizenship__c: PropTypes.string,
        essay_questions_available__c: PropTypes.bool,
        allow_multiple_applications__c: PropTypes.bool,
        access_code_to_edit_after_deadline__c: PropTypes.bool,
        enable_google_authentication__c: PropTypes.bool,
        embed_video_url__c: PropTypes.string,
        carousel_card_click_url__c: PropTypes.string,
        show_open_date_in_card__c: PropTypes.bool,
        sponsor_logo_image__c: PropTypes.string,
        scholarship_header_logo_url__c: PropTypes.string,
        about_the_sponsor_image__c: PropTypes.string,
        about_the_sponsor_description__c: PropTypes.string,
        remove_about_the_sponsor__c: PropTypes.bool,
        essay_questions: PropTypes.arrayOf({
            label__c: PropTypes.string,
            word_count__c: PropTypes.any
        }),
        redirect_user_to_idp: PropTypes.any,
        restricted_access__c: PropTypes.bool,
    })
};

ScholarshipBody.defaultProps = {
    setModalState: () => { },
    secondaryColor: MAIN_CONFIG.COLORS.fillGreen,
    color: MAIN_CONFIG.COLORS.fillDarkBlue,
    previewApplyNow: false,
    handleClickApplyNow: () => { },
    setLoading: () => { },
    showModal: () => { },
    appliedApplication: [],
    checkEnableButton: () => { }
};