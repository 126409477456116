import React, { useState, useEffect } from 'react';
import "./scholarship-header.scss";
import Bolt from '../SVG/Bolt';
import { DESKTOP_WIDTH } from '../../Utility/Breakpoints';
import Pin from '../SVG/Pin';
import FormatNumber from '../../Utility/FormatNumber';
import { DateTime } from 'luxon';
import { handleResize, formatDeadlineDate } from '../../Utility/HelperFunctions';
import ImagePlaceholder from '../../Components/SVG/ImagePlaceholder';
import { MAIN_CONFIG } from '../../../config/main';
import CTAButton from '../CTAButton/CTAButton';
import { useRouter } from 'next/router';
import CTAImageTag from '../CTAImageTag/CTAImageTag';


const { USER_CONTEXT } = MAIN_CONFIG;

/**
 * Render header part of the scholarship details page
 * @param {String} color - Primary color used for header background
 * @param {String} logo - Logo url
 * @param {String} name - Scholarship name
 * @param {String} status
 * @param {Date} date - Apply by date
 * @param {Number} award - Scholarship award amount
 * @returns {JSX.Element}
 */
export default ({ color = '#008291', logo, name, status, date, award, classNameCustom = "", userContext, scholarship, scholarshipHeaderLogo, formType, checkEnableButton = () => { },setLoading=()=>{ }, showBack=false }) => {
    // const formattedDate = DateTime.fromISO(date).toFormat('MMM d, y');
    // const formattedDate = date ? new Date(date).toString() : '';
    const formattedDate = scholarship.deadline_date_time ? formatDeadlineDate(new Date(scholarship.deadline_date_time)) : '';
    const formattedOpenDate = scholarship.open_date ? formatDeadlineDate(new Date(scholarship.open_date)) : '';
    const [isMobile, setIsMobile] = useState(false);
    const formattedAward = (scholarship.scholarship_fund_amount__c) ? FormatNumber(scholarship.scholarship_fund_amount__c) : 0;
    const resize = () => handleResize(setIsMobile, window.innerWidth < DESKTOP_WIDTH);
    const router = useRouter();
    useEffect(() => {
        setIsMobile(window.innerWidth < DESKTOP_WIDTH);
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    const goToBack = () => {
        setLoading(true)
        router.back()
      }
    const renderTop = () => {

        let pinColor = primaryColorConvert == '#ffffff' ? MAIN_CONFIG.COLORS.fillBlack : MAIN_CONFIG.COLORS.fillWhite

        let colorClass = primaryColorConvert == '#ffffff' ? "color__black" : "color__white"


        return (isMobile) ? (
            <>
                {/* {USER_CONTEXT.LOGGED_OUT !== userContext &&
                    <div className="scholarship-body__card-save" onClick={(e) => handleAction(e, scholarship.sfid)}>
                        <button className={scholarship.is_scholarship_bookmarked ? `scholarship-body__card-save-btn CTA ${colorClass}` : `scholarship-body__card-save-btn CTA ${colorClass}`}>{scholarship.is_scholarship_bookmarked ? "Saved" : "Save"}</button>
                        <Pin
                            color={scholarship.is_scholarship_bookmarked ? pinColor : pinColor}
                            ariaLabel="Bookmark"
                            ariaLabelDescription=""
                        />
                    </div>
                } */}
            </>
        ) : (
            <>
                {/* <Bolt
                    ariaLabel="Scholarship Status"
                    ariaLabelDescription=""
                /> */}
                {
                    (status !== 'Prototype' && status !== 'Accepting Applications') ?
                        <span className="scholarship-header__status BodyDefault color__white">{formType === "Donation Form" ? "Form Closed" : " Application closed"}</span>
                        :
                        <span className="scholarship-header__status BodyDefault color__white">{formType === "Donation Form" ? "Accepting Donations" : status}</span>
                }

            </>
        );
    };

    const renderAwardInfo = () => {
        if (isMobile) {
            return (
                <div className="scholarship-header__award-info">
                    {/* <span className="scholarship-header__award">{`$${FormatNumber(award)}`}</span> */}
                    {scholarship.show_fund_amount__c && formType !== "Donation Form" &&
                        <>
                            {
                                scholarship.scholarship_fund_amount__c ?
                                    <span className="scholarship-header__award" style={{ 'color': primaryColorConvert == '#ffffff' ? '#000000' : '#ffffff' }}>{scholarship.show_currency_symbol__c && '$'}  {scholarship.show_fund_amount__c && formattedAward}</span>
                                    :
                                    <span className="scholarship-header__award" style={{ 'color': primaryColorConvert == '#ffffff' ? '#000000' : '#ffffff' }}>{(scholarship.show_currency_symbol__c && scholarship.minimum_fund_amount__c) && '$'}{FormatNumber(scholarship.minimum_fund_amount__c)}  {scholarship.minimum_fund_amount__c && scholarship.maximum_fund_amount__c && ' - '}
                                        {scholarship.maximum_fund_amount__c && scholarship.show_currency_symbol__c && '$'}
                                        {scholarship.maximum_fund_amount__c && FormatNumber(scholarship.maximum_fund_amount__c) || ''}</span>
                            }
                        </>
                    }
                    <span className="scholarship-header__date" style={{ 'color': primaryColorConvert == '#ffffff' ? '#000000' : '#ffffff' }}>
                        {(scholarship.status__c === 'Accepting Applications' && scholarship.show_open_date_in_card__c == true && checkEnableButton()) ?
                            formattedOpenDate && `Open: ${formattedOpenDate}` :
                            (scholarship.status__c === 'Accepting Applications' && !scholarship.deadline_date_time) ?
                                scholarship.form_type__c !== "Donation Form" ? 'Accepting Applications' :
                                    'Accepting Donations'
                                : formattedDate && `Submit by ${formattedDate}`}
                    </span>
                    {/* <span className="scholarship-header__date" style={{ 'color': primaryColorConvert == '#ffffff' ? '#000000' : '#ffffff' }}>{(scholarship.status__c === 'Accepting Applications' && !scholarship.deadline_date_time) ? (formType !== "Donation Form" && 'Accepting Applications') : 'Submit by'} {formattedDate}</span> */}
                </div>
            );
        }
    };


    var primaryColorConvert = color ? color.toLowerCase() : ''

    return (
        <div className="scholarship-header__wrapper" style={{ backgroundColor: color }}>
            <div className={`scholarship-header ${classNameCustom}`}>
                <div className="scholarship-header__top-wrapper">
                    {/* <div className="scholarship-header__top">{renderTop()}</div> */}
                </div>
                <div className="scholarship-header__content">
                    {showBack &&
                        <div className='scholarship-header__back-move'>
                            <CTAButton
                                className="transparent-CTAButton outline"
                                onClick={goToBack}
                                arrow={true}
                                reverse={true}
                                type="tertiary"
                            >
                                Back
                            </CTAButton>
                        </div>
                    }
                {/* <span> back </span> */}
                    {
                        (logo || scholarshipHeaderLogo)
                            ?
                            <div className='scholarship-logo-container'>
                                <CTAImageTag data={logo ? logo : scholarshipHeaderLogo} title={`${name} logo`} className="scholarship-header__logo" />
                            </div>
                            :
                            <ImagePlaceholder
                                className="scholarship-header__logo"
                                ariaLabel="Scholarship Logo"
                                ariaLabelDescription=""
                            />
                    }
                    {/* {name && <h1 className="scholarship-header__name color__white" style={{ 'color': primaryColorConvert == '#ffffff' ? '#000000' : '#ffffff' }}>{name}</h1>} */}
                    {/* {renderAwardInfo()} */}
                </div>
            </div>
        </div>
    );
};
